import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {errorMessage} from "../../utils/messages";
import "moment/locale/it";
import PazientiService from "../../services/PazientiService";
import moment from "moment";

export const initialState = {
    //richiami-rinnovo
    richiamoRinnnovo: [],
    loadingRichiamoRinnnovo: false,
    loadedRichiamoRinnnovo: false,
    errorRichiamoRinnnovo: false,
    //richiami-follow up
    richiamoFollowUp: [],
    loadingRichiamoFollowUp: false,
    loadedRichiamoFollowUp: false,
    errorRichiamoFollowUp: false,

}

export const loadRichiamiRinnovo = createAsyncThunk('carica-richiami-rinnovo', async (input, {rejectWithValue, getState}) => {

    console.log("[loadRichiamiRinnovo]")
    try {

        //OTTENERE GLI UTENTI
        //const response = await CalendarService.getEvents(puntivendita, start, end);
        const pazienti = getState().pazienti.pazienti

        const annoAttuale = new Date().getFullYear();
        const annoRiferimento = annoAttuale - 4;

        const pazientiConAnniInferiori = pazienti.filter(paziente =>
            paziente.anno_di_vendita.length > 0 &&
            paziente.anno_di_vendita.every(anno => anno < annoRiferimento) &&
            (paziente.tipo_paziente==="MUTUA" || paziente.tipo_paziente==="RICONDUCIBILE" || paziente.tipo_paziente==="PRIVATO")
        );

        return pazientiConAnniInferiori

    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare i richiami. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const loadRichiamiFollowUp = createAsyncThunk('carica-richiami-followup', async (input, {rejectWithValue, getState}) => {

    console.log("[loadRichiamiFollowUp]")
    try {

        const data = moment().add(2,'months').endOf('month').add('1','days').format('YYYY-MM-DD')
        const response = await PazientiService.getRichiamiFollowUp(data);
        return response.data

    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare i richiami. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const RichiamiSlice = createSlice({
    name: 'richiami',
    initialState,
    reducers: {
        resetState: (state, action) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadRichiamiRinnovo.pending, (state) => {
                state.loadingRichiamoRinnnovo = true
                state.loadedRichiamoRinnnovo = false
                state.errorRichiamoRinnnovo = false
            })
            .addCase(loadRichiamiRinnovo.fulfilled, (state, action) => {
                state.loadingRichiamoRinnnovo = false
                state.richiamoRinnnovo = action.payload
                state.loadedRichiamoRinnnovo = true
                state.errorRichiamoRinnnovo = false
            })
            .addCase(loadRichiamiRinnovo.rejected, (state, action) => {
                state.loadingRichiamoRinnnovo = false
                state.richiamoRinnnovo = []
                state.loadedRichiamoRinnnovo = true
                state.errorRichiamoRinnnovo = true
            })
            .addCase(loadRichiamiFollowUp.pending, (state) => {
                state.loadingRichiamoFollowUp = true
                state.loadedRichiamoFollowUp = false
                state.errorRichiamoFollowUp = false
            })
            .addCase(loadRichiamiFollowUp.fulfilled, (state, action) => {
                state.loadingRichiamoFollowUp = false
                state.richiamoFollowUp = action.payload
                state.loadedRichiamoFollowUp = true
                state.errorRichiamoFollowUp = false
            })
            .addCase(loadRichiamiFollowUp.rejected, (state, action) => {
                state.loadingRichiamoFollowUp = false
                state.richiamoFollowUp = []
                state.loadedRichiamoFollowUp = true
                state.errorRichiamoFollowUp = true
            })

    }
})


export default RichiamiSlice.reducer;