import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {errorMessage} from "../../utils/messages";
import CalendarService from "../../services/CalendarService";
import moment from "moment";
import "moment/locale/it";
import store from "../index";
import {getIndexEnd, getIndexStart} from 'views/app-views/agenda/sub-component/config';
import {editProssimoApputamento} from "./pazientiSlice";
import SchedaPazienteService from "../../services/SchedaPazienteService";


export const initialState = {
    //eventi
    events: [],
    //stati dicaricmento degli eventi iniziali o quando supero il range
    loadingEvents: false,
    loadedEvents: false,
    errorEvents: false,
    //RIFERIMENTI CHE MI MANTENGONO AGGIORNATO GLI l'INIZIO E LA FINE DI DOVE HO GIA SCARICATO
    indexStart: getIndexStart(),
    indexEnd: getIndexEnd(),
    //stati di carimento quando modifico aggiungo evento
    loadingAddEditEvents: false,
    loadedAddEditEvents: false,
    errorAddEditEvents: false,

}

export const loadEvents = createAsyncThunk('carica-eventi', async (input, {rejectWithValue, getState}) => {

    console.log("[loadEvents]")
    try {

        // console.log("[loadEvents]", getState())
        const firstTime = input.firstTime

        let start = null;
        let end = null;
        if (firstTime) {
            store.dispatch(CalendarSlice.actions.resetState());
            store.dispatch(setIndexStart(getIndexStart()));
            store.dispatch(setIndexEnd(getIndexEnd()));
            start = getState().events.indexStart.format('YYYY-MM-DD HH:mm')
            end = getState().events.indexEnd.format('YYYY-MM-DD HH:mm')
        } else {

            start = input.start.format('YYYY-MM-DD HH:mm')
            end = input.end.format('YYYY-MM-DD HH:mm')
        }


        let eventiPresenti = getState().events.events
        const puntivendita = getState().puntiVendita.puntiVendita

        /*const start = input.start.format('YYYY-MM-DD HH:mm')
        const end = input.end.format('YYYY-MM-DD HH:mm')*/

        //OTTENERE GLI UTENTI
        const response = await CalendarService.getEvents(puntivendita, start, end);
        const data = response.data

        let newEvents = data.map(event => (setEvent(event)))
        console.table(eventiPresenti, newEvents)
        return [...eventiPresenti, ...newEvents]

    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare gli eventi. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const addNewEvent = createAsyncThunk('aggiungi-eventi', async (body, {rejectWithValue, getState}) => {
    try {


        const currentState = getState().events.events;


        if ((typeof body.data.start.format === 'function') && (getState().events.indexEnd.isBefore(moment(body.data.start.format())) || getState().events.indexStart.isAfter(moment(body.data.end.format())))) {

            //DELETE MESSA PER ADATTAMENTO A STRAPI 5
            const checked = body.data.isChecked
            delete body.data.isChecked;

            const response = await CalendarService.addEvent(body);

            /* AGGIORNO ULTIMO APPUNTAMENTO */
            if(body.data.tipo!=="nota" && checked!==true){
                console.log("body.data.patient",body.data.patient)
                const salesPatientReponse = await SchedaPazienteService.getSalesPatient(body.data.patient)
                const salesPatient = salesPatientReponse?.data?.attributes?.sales_patients
                console.log("body.data.patient salesPatient",salesPatient)
                let newCronologia = salesPatient == null ? [] : salesPatient.data.map(item => {
                    const { id, attributes } = item;
                    const { data, note, stato, referente, tipologia } = attributes;

                    return {
                        id: id.toString(),
                        data,
                        note,
                        stato,
                        referente,
                        tipologia
                    };
                });
                await store.dispatch(editProssimoApputamento({id:body.data.patient,cronologia:newCronologia}))
            }
            return [...currentState]

        } else {

            //DELETE MESSA PER ADATTAMENTO A STRAPI 5
            const checked = body.data.isChecked
            delete body.data.isChecked;

            const response = await CalendarService.addEvent(body);
            /* AGGIORNO ULTIMO APPUNTAMENTO */
            try {
                if (body.data.tipo !== "nota" && checked !== true) {
                    const salesPatientReponse = await SchedaPazienteService.getSalesPatient(body.data.patient)
                    const salesPatient = salesPatientReponse?.data?.attributes?.sales_patients
                    let newCronologia = salesPatient == null ? [] : salesPatient.data.map(item => {
                        const {id, attributes} = item;
                        const {data, note, stato, referente, tipologia} = attributes;

                        return {
                            id: id.toString(),
                            data,
                            note,
                            stato,
                            referente,
                            tipologia
                        };
                    });
                    await store.dispatch(editProssimoApputamento({id: body.data.patient, cronologia: newCronologia}))
                }
            }catch (e) {
                console.error(e)
            }

            return [...currentState, ...[setEvent(response.data)]];
        }
    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Non è stato possibile aggiungere l'evento.
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
});

export const editEvent = createAsyncThunk('modifica-eventi', async (input, {rejectWithValue, getState}) => {
    try {
        let currentState = getState().events.events.map((item) => ({
            ...item,
        }))
        if ((typeof input.body.data.start.format === 'function') && (getState().events.indexEnd.isBefore(moment(input.body.data.start.format())) || getState().events.indexStart.isAfter(moment(input.body.data.end.format())))) {

            //DELETE MESSA PER ADATTAMENTO A STRAPI 5
            const checked = input.body.data.isChecked
            delete input.body.data.isChecked;

            await CalendarService.editEvent(input.id, input.body);

            /* AGGIORNO ULTIMO APPUNTAMENTO */
            try {

                if (input.body.data.tipo !== "nota" && checked !== true && input.paziente !== undefined) {
                    let patientId = input.body.data.patient
                    if(input.paziente!==-1){
                        patientId=JSON.parse(localStorage.getItem("pazientiMap"))[input.paziente.trim()].id
                    }
                    console.log("patientId",patientId)
                    const salesPatientReponse = await SchedaPazienteService.getSalesPatient(patientId)
                    const salesPatient = salesPatientReponse?.data?.attributes?.sales_patients
                    let newCronologia = salesPatient == null ? [] : salesPatient.data.map(item => {
                        const {id, attributes} = item;
                        const {data, note, stato, referente, tipologia} = attributes;

                        return {
                            id: id.toString(),
                            data,
                            note,
                            stato,
                            referente,
                            tipologia
                        };
                    });
                    await store.dispatch(editProssimoApputamento({id: patientId, cronologia: newCronologia}))
                }
            }
            catch (e) {
                console.error(e)
            }


            const indexobj = currentState.findIndex((element) => element.documentId === input.id);
            currentState.splice(indexobj, 1)
            return currentState

        } else {

            //DELETE MESSA PER ADATTAMENTO A STRAPI 5
            const checked = input.body.data.isChecked
            delete input.body.data.isChecked;

            const response = await CalendarService.editEvent(input.id, input.body);

            /* AGGIORNO ULTIMO APPUNTAMENTO */
            try {
                if (input.body.data.tipo !== "nota" && checked!==true && input.paziente!==undefined) {
                    let patientId=input.body.data.patient
                    if(input.paziente!==-1){
                        patientId=JSON.parse(localStorage.getItem("pazientiMap"))[input.paziente.trim()].id
                    }
                    const salesPatientReponse = await SchedaPazienteService.getSalesPatient(patientId)
                    const salesPatient = salesPatientReponse?.data?.attributes?.sales_patients
                    let newCronologia = salesPatient == null ? [] : salesPatient.data.map(item => {
                        const {id, attributes} = item;
                        const {data, note, stato, referente, tipologia} = attributes;

                        return {
                            id: id.toString(),
                            data,
                            note,
                            stato,
                            referente,
                            tipologia
                        };
                    });
                    await store.dispatch(editProssimoApputamento({id: patientId, cronologia: newCronologia}))
                }
            }catch (e) {
                console.error(e)
            }


            const indexobj = currentState.findIndex((element) => element.documentId === input.id);
            currentState[indexobj] = setEvent(response.data);
            return currentState
        }
    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Non è stato possibile modificare l'evento.
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
});

export const editEventState = createAsyncThunk('modifica-stato-eventi', async (input, {rejectWithValue, getState}) => {
    try {
        console.log("editEventState input",input)
        let currentState = getState().events.events.map((item) => ({
            ...item,
        }))
        const response = await CalendarService.editEvent(input.id, {
            "data":{
                "stato":input.state
            }
        });

        const indexobj = currentState.findIndex((element) => element.documentId === input.id);
        currentState[indexobj] = setEvent(response.data);
        return currentState
    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Non è stato possibile modificare l'evento.
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
});

export const deleteEvent = createAsyncThunk('elimina-evento', async (input, {rejectWithValue, getState}) => {
    try {

        //EVENTO
        let id = input.documentId;
        let currentState = getState().events.events.map((item) => ({
            ...item,
        }))
        await CalendarService.deleteEvent(id);

        /* AGGIORNO ULTIMO APPUNTAMENTO */
        try {
            if (input.tipo !== "nota") {

                let patientId = input.pazientiLabel.split('-')[0].trim(); // Dividi la stringa usando il trattino come delimitatore e prendi il primo elemento
                patientId=JSON.parse(localStorage.getItem("pazientiMap"))[patientId].id

                const salesPatientReponse = await SchedaPazienteService.getSalesPatient(patientId)
                const salesPatient = salesPatientReponse?.data?.attributes?.sales_patients
                let newCronologia = salesPatient == null ? [] : salesPatient.data.map(item => {
                    const {id, attributes} = item;
                    const {data, note, stato, referente, tipologia} = attributes;

                    return {
                        id: id.toString(),
                        data,
                        note,
                        stato,
                        referente,
                        tipologia
                    };
                });
                await store.dispatch(editProssimoApputamento({id: patientId, cronologia: newCronologia}))
            }
        }catch (e) {
            console.error(e)
        }


        const indexobj = currentState.findIndex((element) => element.documentId === id);
        currentState.splice(indexobj, 1)
        return currentState

    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Non è stato possibile modificare l'evento.
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
});


export const setIndexStart = createAction('setIndexStart');
export const setIndexEnd = createAction('setIndexEnd');

//funzioni locali
const setEvent = (event) => {
    return {
        documentId: event.documentId,
        id: event.id,
        start: new Date(event.attributes.start),
        end: new Date(event.attributes.end),
        title: event.attributes.nome_appuntamento,
        note: event.attributes.note,
        stato: event.attributes.stato,
        tipo: event.attributes.tipo,
        pazientiLabel: event.attributes.pazientiLabel,
        store: event.attributes.storeLabel,
        color: "#" + event.attributes.colore,
        operatore: event.attributes.operatoreLabel,
        //color:"#"+event.attributes.operatore.data.attributes.color,
        //operatore:event.attributes.operatore.data.id,
        //store:event.attributes.store.data.id,
    }
}
export const CalendarSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        resetState: (state, action) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadEvents.pending, (state) => {
                state.loadingEvents = true
                state.loadedEvents = false
                state.errorEvents = false
            })
            .addCase(loadEvents.fulfilled, (state, action) => {
                state.loadingEvents = false
                state.events = action.payload
                state.loadedEvents = true
                state.errorEvents = false
            })
            .addCase(loadEvents.rejected, (state, action) => {
                state.loadingEvents = false
                state.events = []
                state.loadedEvents = true
                state.errorEvents = true
            })
            .addCase(addNewEvent.pending, (state) => {
                state.loadingAddEditEvents = true
                state.loadedAddEditEvents = false
                state.errorAddEditEvents = false
            })
            .addCase(addNewEvent.fulfilled, (state, action) => {
                state.events = action.payload
                state.loadingAddEditEvents = false
                state.loadedAddEditEvents = true
                state.errorAddEditEvents = false
            })
            .addCase(addNewEvent.rejected, (state, action) => {
                state.loadingAddEditEvents = false
                state.loadedAddEditEvents = true
                state.errorAddEditEvents = true
            })
            .addCase(editEvent.pending, (state) => {
                state.loadingAddEditEvents = true
                state.loadedAddEditEvents = false
                state.errorAddEditEvents = false
            })
            .addCase(editEvent.fulfilled, (state, action) => {
                state.events = action.payload
                state.loadingAddEditEvents = false
                state.loadedAddEditEvents = true
                state.errorAddEditEvents = false
            })
            .addCase(editEvent.rejected, (state, action) => {
                state.loadingAddEditEvents = false
                state.loadedAddEditEvents = true
                state.errorAddEditEvents = true
            })
            .addCase(editEventState.pending, (state) => {
                state.loadingAddEditEvents = true
                state.loadedAddEditEvents = false
                state.errorAddEditEvents = false
            })
            .addCase(editEventState.fulfilled, (state, action) => {
                state.events = action.payload
                state.loadingAddEditEvents = false
                state.loadedAddEditEvents = true
                state.errorAddEditEvents = false
            })
            .addCase(editEventState.rejected, (state, action) => {
                state.loadingAddEditEvents = false
                state.loadedAddEditEvents = true
                state.errorAddEditEvents = true
            })
            .addCase(deleteEvent.pending, (state) => {
                state.loadingAddEditEvents = true
                state.loadedAddEditEvents = false
                state.errorAddEditEvents = false
            })
            .addCase(deleteEvent.fulfilled, (state, action) => {
                state.events = action.payload
                state.loadingAddEditEvents = false
                state.loadedAddEditEvents = true
                state.errorAddEditEvents = false
            })
            .addCase(deleteEvent.rejected, (state, action) => {
                state.loadingAddEditEvents = false
                state.loadedAddEditEvents = true
                state.errorAddEditEvents = true
            })
            .addCase(setIndexStart, (state, action) => {
                state.indexStart = action.payload;
            })
            .addCase(setIndexEnd, (state, action) => {
                state.indexEnd = action.payload;
            })



    }
})


export default CalendarSlice.reducer;