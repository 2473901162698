import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {PUNTI_VENDITA} from 'configs/AppConfig'
import PuntiVenditaService from "../../services/PuntiVenditaService";
import {errorMessage} from "../../utils/messages";
import {
    PROVENIENZE_VALUE,
    PUNTI_VENDITA_ERROR,
    PUNTI_VENDITA_LOADED,
    PUNTI_VENDITA_VALUE
} from "../../constants/AuthConstant";

export const initialState = PUNTI_VENDITA

export const loadPuntiVendita = createAsyncThunk('carica-punti-vendita', async (data, {rejectWithValue}) => {
    console.log("[loadPuntiVendita]");
    try {

        const response = data.binding_roles;
        let puntiVendita = []
        for (let i = 0; i < response.length; i++) {
            let puntoVendita = response[i].store
            puntiVendita.push({
                id: puntoVendita.id,
                nome: puntoVendita.name,
                documentId:puntoVendita.documentId
            })
        }

        console.table(puntiVendita)
        localStorage.setItem(PUNTI_VENDITA_VALUE, JSON.stringify(puntiVendita))
        return puntiVendita
    } catch (err) {
        console.log("err", err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare gli Studi di Riferimento. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const puntiVendita = createSlice({
    name: 'puntiVendita', initialState, reducers: {

        showLoading: (state) => {
            state.loadingPuntiVendita = true
        },
    }, extraReducers: (builder) => {
        builder
            .addCase(loadPuntiVendita.pending, (state) => {
                state.loadingPuntiVendita = true
                state.loadedPuntiVendita = false
                state.errorPuntiVendita = false
            })
            .addCase(loadPuntiVendita.fulfilled, (state, action) => {
                state.loadingPuntiVendita = false
                state.loadedPuntiVendita = true
                state.puntiVendita = action.payload
                localStorage.setItem(PUNTI_VENDITA_LOADED, true)
                localStorage.setItem(PUNTI_VENDITA_ERROR, false)
                state.errorPuntiVendita = false
            })
            .addCase(loadPuntiVendita.rejected, (state, action) => {
                state.loadingPuntiVendita = false
                state.loadedPuntiVendita = true
                state.puntiVendita = []
                localStorage.setItem(PUNTI_VENDITA_LOADED, true)
                localStorage.setItem(PUNTI_VENDITA_ERROR, true)
                localStorage.removeItem(PUNTI_VENDITA_VALUE)
                state.errorPuntiVendita = true
            })
    }
})

export const {
    showLoading
} = puntiVendita.actions

export default puntiVendita.reducer;