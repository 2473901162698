import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {errorMessage} from "../../utils/messages";
import DashboardService from "../../services/DashboardService";
import moment from "moment";

export const initialState = {
    //INCASSI
    loadingIncassi: false,
    incassi: [],
    fatturato: [],
    loadedIncassi: false,
    errorIncassi: false,
    selectedStore: null,
    selectedStart: null,
    selectedEnd: null,

    //FINANZIAMENTI ATTIVI
    loadingFinanziamentiAttivi: false,
    finanziamentiAttivi: {},
    loadedFinanziamentiAttivi: false,
    errorFinanziamentiAttivi: false,

    //ANDAMENTO VENDITE
    loadingAndamentoVendite: false,
    andamentoVendite: {},
    loadedAndamentoVendite: false,
    errorAndamentoVendite: false,

};

export const loadIncassiRange = createAsyncThunk('carica-incassi', async (data, {rejectWithValue}) => {
    console.log("[loadIncassiEFatturatoRange]", data);
    const {store, start, end} = data;

    try {


        const saleHistorySaldatiPromise = DashboardService.getSaleHistorySaldati(store, start, end)
        const purchaseFromToPromise = DashboardService.getPurchaseFromTo(store, start, end)
        const [saleHistorySaldati, purchaseFromTo] = await Promise.all([saleHistorySaldatiPromise, purchaseFromToPromise])

        const incassiTotali = saleHistorySaldati.data
        const fatturatoTotale = purchaseFromTo.data

        const totaleSoluzioneUnicaIncassi = incassiTotali.filter((incasso) => incasso.attributes.tipologia === 'SOLUZIONE UNICA')
            .map(item => item.attributes.rata)
            .reduce((sum, current) => sum + current, 0);
        const totaleFinanzimentoIncassi = incassiTotali.filter((incasso) => incasso.attributes.tipologia === "FINANZIAMENTO")
            .map(item => item.attributes.rata)
            .reduce((sum, current) => sum + current, 0);
        const totalePiuSoluzioniIncassi = incassiTotali.filter((incasso) => incasso.attributes.tipologia === "PIU SOLUZIONI")
            .map(item => item.attributes.rata)
            .reduce((sum, current) => sum + current, 0);
        const totalePiuSoluzioniRatePersonalizzate = incassiTotali.filter((incasso) => incasso.attributes.tipologia === "RATE PERSONALIZZATE")
            .map(item => item.attributes.rata)
            .reduce((sum, current) => sum + current, 0);


        const totaleSoluzioneUnicaFatturato = fatturatoTotale.filter((fatturato) => fatturato.attributes.soluzione_pagamento === 'SOLUZIONE UNICA')
            .map(item => item.attributes.importo_totale)
            .reduce((sum, current) => sum + current, 0);
        const totaleFinanzimentoFatturato = fatturatoTotale.filter((fatturato) => fatturato.attributes.soluzione_pagamento === "FINANZIAMENTO")
            .map(item => item.attributes.importo_totale)
            .reduce((sum, current) => sum + current, 0);
        const totalePiuSoluzioniFatturato = fatturatoTotale.filter((fatturato) => fatturato.attributes.soluzione_pagamento === "PIU SOLUZIONI")
            .map(item => item.attributes.importo_totale)
            .reduce((sum, current) => sum + current, 0);
        const totaleRatePersonalizzateFatturato = fatturatoTotale.filter((fatturato) => fatturato.attributes.soluzione_pagamento === "RATE PERSONALIZZATE")
            .map(item => item.attributes.importo_totale)
            .reduce((sum, current) => sum + current, 0);

        return {
            incassi: [
                {label: 'FINANZIAMENTO', value: totaleFinanzimentoIncassi},
                {label: 'SOLUZIONE UNICA', value: totaleSoluzioneUnicaIncassi},
                {label: 'PIU SOLUZIONI', value: totalePiuSoluzioniIncassi},
                {label: 'RATE PERSONALIZZATE', value: totalePiuSoluzioniRatePersonalizzate}
            ],
            fatturato: [
                {label: 'FINANZIAMENTO', value: totaleFinanzimentoFatturato},
                {label: 'SOLUZIONE UNICA', value: totaleSoluzioneUnicaFatturato},
                {label: 'PIU SOLUZIONI', value: totalePiuSoluzioniFatturato},
                {label: 'RATE PERSONALIZZATE', value: totaleRatePersonalizzateFatturato}
            ],
            start: start,
            end: end,
            store: store
        }

    } catch (err) {
        console.error(err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare gli incassi. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const loadFinanziamentiAttivi = createAsyncThunk('carica-finanziamenti-attivi', async (puntiVendita, {rejectWithValue}) => {
    console.log("[loadFinanziamentiAttivi]", puntiVendita);


    try {


        const getSaleHistoryPromise = DashboardService.getAllSaleHistoryNoWelded(puntiVendita)
        const getRatePersonalizzatePromise = DashboardService.getRatePersonalizzate(puntiVendita)
        const contaFinanziamentiPromise = DashboardService.contaRateizzazioniInCorso(puntiVendita)


        const [saleHistory, contaFinanziamenti, ratePersonalizzate] = await Promise.all([
            getSaleHistoryPromise,
            contaFinanziamentiPromise,
            getRatePersonalizzatePromise
        ]);


        let totalAmount = 0;
        const stores = []
        puntiVendita.map(puntoVendita => {

            //QUESTO HA LO SCOPO DI CALCOLARE IL TOTALE DA INCASSARE PER PUNTO VENDITA
            //TOTALE DA INCASSARE (X PUNTO VENDITA) ESCLUDENDO LE RATE PERSONALIZZATE
            //SI ESCLUDONO LE RATE PERSONALIZZATE PERCHE' LA LOGICA PER IL CALCOLO DEL RESIDUO E' DIFFERENTE
            const storeAmountSenzaRatePersonalizzate = saleHistory.data
                .filter(item => item.attributes.purchase.data.attributes.store.data.documentId === puntoVendita.documentId
                    && item?.attributes?.purchase?.data?.attributes?.soluzione_pagamento !== 'RATE PERSONALIZZATE')
                .reduce((sum, item) => {
                    return sum + item.attributes.rata;
                }, 0);

            //TOTALE DA INCASSARE  (X PUNTO VENDITA) RATE PERSONALIZZATE
            const storeAmountRatePersonalizzate = ratePersonalizzate.data
                .filter(item => item.attributes.store.data.documentId === puntoVendita.documentId)
                .reduce((acc, item) => {
                    const importoTotale = item.attributes.importo_totale;
                    const rateSaldate = item.attributes.sales_histories.data
                        .filter(sale => sale.attributes.rata_saldata)
                        .reduce((sum, sale) => sum + sale.attributes.rata, 0);

                    return acc + (importoTotale - rateSaldate);
                }, 0);
            const storeAmount = storeAmountRatePersonalizzate + storeAmountSenzaRatePersonalizzate

            //TODO DA RIFARE
            //QUESTO HA LO SCOPO DI CAPIRE QUANTO SI DEVE INCASSARE DA SOLO DALLE RATE
            //TOTALE DA INCASSARE DALLE VENDITA CON PIU' SOLUZIONI
            const storeRateizzazioniAmount = saleHistory.data.filter(item =>
                item.attributes.purchase.data.attributes.store.data.documentId === puntoVendita.documentId &&
                item.attributes.tipologia === "PIU SOLUZIONI" &&
                item.attributes.is_mutua === null &&
                item.attributes.is_acconto === null
            ).reduce((sum, item) => {
                return sum + item.attributes.rata;
            }, 0);

            //DA INCASSARE DA SOLO LE RATE DELLA RATEIZZAZIONI
            const storeRateizzazioniAmountRatePersonalizzate = ratePersonalizzate.data
                .filter(item => item.attributes.store.data.documentId === puntoVendita.documentId)
                .reduce((totalSum, item) => {
                    const {importo_totale, sales_histories} = item.attributes;

                    // Filtra le sale_history che soddisfano le condizioni
                    const matchingSales = sales_histories.data.filter((sale) => {
                        const {is_mutua, is_acconto, rata_saldata} = sale.attributes;
                        return (
                            (is_mutua === null && is_acconto === true) ||
                            (is_mutua === true && is_acconto === null) ||
                            (is_mutua === false && is_acconto === false && rata_saldata === true)
                        );
                    });

                    // Calcola il totale da sottrarre come somma delle "rata" delle sale_history filtrate
                    const totalRataToSubtract = matchingSales.reduce((sum, sale) => {
                        return sum + sale.attributes.rata;
                    }, 0);

                    // Calcola il netto per questo item e aggiungilo al totale complessivo
                    const netImporto = importo_totale - totalRataToSubtract;
                    return totalSum + netImporto;
                }, 0);


            //CONTEGGIO RATEIZZAZIONI PIU SOLUZIONI
            const filteredStore = contaFinanziamenti.data.filter(item =>
                item.attributes.store.data.documentId === puntoVendita.documentId
            )
            //CONTEGGIO RATEIZZAZIONI RATE PERSONALIZZATE
            const countResiduoMaggioreDiZero = ratePersonalizzate.data
                .filter(item => item.attributes.store.data.documentId === puntoVendita.documentId)
                .filter(item => {
                    const importoTotale = item.attributes.importo_totale;
                    const rateSaldate = item.attributes.sales_histories.data
                        .filter(sale => sale.attributes.rata_saldata)
                        .reduce((sum, sale) => sum + sale.attributes.rata, 0);

                    const importoResiduo = importoTotale - rateSaldate;
                    return importoResiduo > 0;
                })

            stores.push({
                "nome": puntoVendita.nome,
                "totaleDaIncassare": storeAmount,
                "totaleDaIncassaRateizzazioni": storeRateizzazioniAmount + storeRateizzazioniAmountRatePersonalizzate,
                "numeroRateizzazioni": filteredStore.length + countResiduoMaggioreDiZero.length
            })

            totalAmount = totalAmount + storeAmount
        });

        return {
            "totale": totalAmount,
            "dettaglioStore": stores
        }

    } catch (err) {
        console.error(err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare i finanziamenti. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})
const getMonthString = (month) => {
    const months = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
    return months[month];
};

function calcolaPercentualeCrescita(numeroIniziale, numeroFinale) {
    let differenza = numeroFinale - numeroIniziale;
    let percentualeCrescita = (differenza / numeroIniziale) * 100;
    return percentualeCrescita.toFixed(2); // Arrotonda a due decimali
}

export const loadAndamentoVendite = createAsyncThunk('carica-andamento-vendite', async (store, {rejectWithValue}) => {
    console.log("[loadAndamentoVendite]", store);


    try {

        const saleHistorySaldatiPromise = DashboardService.getSaleHistorySaldati(
            store, moment().startOf('year').format("YYYY-MM-DD"),
            moment().endOf('month').format("YYYY-MM-DD")
        )
        const purchaseFromToPromise = DashboardService.getPurchaseFromTo(
            store,
            moment().startOf('year').format("YYYY-MM-DD"),
            moment().endOf('month').format("YYYY-MM-DD")
        )
        const [saleHistory, purchaseFromTo] = await Promise.all([
            saleHistorySaldatiPromise, purchaseFromToPromise
        ]);

        const incassiTotaliAnno = saleHistory.data
        const datturatoTotaleAnno = purchaseFromTo.data

        let fatturatoMese = [];
        let incassiMese = [];
        let mesi = [];
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        for (let month = 0; month <= currentDate.getMonth(); month++) {
            let monthStart = new Date(currentYear, month, 1);
            let monthEnd = new Date(currentYear, month + 1, 0); // Ultimo giorno del mese
            monthEnd.setHours(23, 59, 59, 999)

            let totalRata = 0;
            incassiTotaliAnno.forEach(item => {
                let scadenzaRata = new Date(item.attributes.scadenza_rata);
                if (scadenzaRata >= monthStart && scadenzaRata <= monthEnd) {
                    totalRata += item.attributes.rata;
                }
            });
            let totalImporto = 0;
            datturatoTotaleAnno.forEach(item => {
                let scadenzaRata = new Date(item.attributes.data_vendita);
                if (scadenzaRata >= monthStart && scadenzaRata <= monthEnd) {
                    totalImporto += item.attributes.importo_totale;
                }
            });
            fatturatoMese.push(totalImporto);
            incassiMese.push(totalRata);

            mesi.push(`${getMonthString(month)} ${currentYear}`);
        }

        return {
            totaleIncassatoMeseCorrente: incassiMese[incassiMese.length - 1],
            crescitaMeseCorrente:
                incassiMese.length >= 2
                    ?
                    calcolaPercentualeCrescita(
                        incassiMese[incassiMese.length - 2] + 1,
                        incassiMese[incassiMese.length - 1]
                    )
                    :
                    100,

            xAxis: mesi,
            totaleIncassato: incassiMese,
            totaleFatturato: fatturatoMese
        }


    } catch (err) {
        console.error(err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare gli andamenti delle vendite. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})
export const dashboardVendite = createSlice({
    name: 'dashboardVendite', initialState, reducers: {}, extraReducers: (builder) => {
        builder
            .addCase(loadIncassiRange.pending, (state) => {

                state.loadingIncassi = true
                state.loadedIncassi = false
                state.errorIncassi = false
            })
            .addCase(loadIncassiRange.fulfilled, (state, action) => {
                state.loadingIncassi = false
                state.loadedIncassi = true
                state.incassi = action.payload.incassi
                state.fatturato = action.payload.fatturato
                state.selectedStart = action.payload.start
                state.selectedEnd = action.payload.end
                state.selectedStore = action.payload.store
                state.errorIncassi = false

            })
            .addCase(loadIncassiRange.rejected, (state, action) => {
                state.loadingIncassi = false
                state.loadedIncassi = true
                state.incassi = []
                state.fatturato = []
                state.start = null
                state.end = null
                state.selectedStore = null
                state.errorIncassi = true
            })
            .addCase(loadFinanziamentiAttivi.pending, (state) => {

                state.loadingFinanziamentiAttivi = true
                state.loadedFinanziamentiAttivi = false
                state.errorFinanziamentiAttivi = false
            })
            .addCase(loadFinanziamentiAttivi.fulfilled, (state, action) => {
                state.loadingFinanziamentiAttivi = false
                state.loadedFinanziamentiAttivi = true
                state.finanziamentiAttivi = action.payload
                state.errorFinanziamentiAttivi = false

            })
            .addCase(loadFinanziamentiAttivi.rejected, (state, action) => {
                state.loadingFinanziamentiAttivi = false
                state.loadedFinanziamentiAttivi = true
                state.finanziamentiAttivi = {}
                state.errorFinanziamentiAttivi = true


            })


            .addCase(loadAndamentoVendite.pending, (state) => {

                state.loadingAndamentoVendite = true
                state.loadedFinanziamentiAttivi = false
                state.errorAndamentoVendite = false

            })
            .addCase(loadAndamentoVendite.fulfilled, (state, action) => {
                state.loadingAndamentoVendite = false
                state.loadedFinanziamentiAttivi = true
                state.andamentoVendite = action.payload
                state.errorAndamentoVendite = false

            })
            .addCase(loadAndamentoVendite.rejected, (state, action) => {
                state.loadingAndamentoVendite = false
                state.loadedFinanziamentiAttivi = true
                state.andamentoVendite = {}
                state.errorAndamentoVendite = true
            })


    }
})


export default dashboardVendite.reducer;