import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { errorMessage } from "../../utils/messages";
import DashboardService from "../../services/DashboardService";

export const initialState = {
    //STATISTICHE VISITE
    loadingStatisticheVisite: false,
    statisticheVisite: {},
    loadedStatisticheVisite: false,
    errorStatisticheVisite: false,
    //STATISTICHE PAZIENTI
    loadingStatistichePazienti: false,
    loadingStatisticheProvenienze: false,
    statisticheProvenienze: {},
    statistichePazienti: {},
    loadedStatistichePazienti: false,
    loadedStatisticheProvenienze: false,
    errorStatistichePazienti: false,
    errorStatisticheProvenienze: false,
    annoDiVendita: "",
    type: "categoria"
};

export const loadStatisticsVisits = createAsyncThunk('carica-statistiche-visite', async (data, { rejectWithValue }) => {
    console.log("[loadStatisticsVisits]");
    const { puntiVendita } = data;
    try {
        const conteggioVisitePromise = DashboardService.countVisits(puntiVendita);
        const [visite] = await Promise.all([conteggioVisitePromise]);
        return visite.visite;

    } catch (err) {
        console.error(err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare le statistiche delle visite. Riprovare!
                <br />
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const loadStatisticsPatients = createAsyncThunk('carica-statistiche-pazienti', async (data, { rejectWithValue }) => {
    console.log("[loadStatisticsVisits]");
    const { puntiVendita, annoDiVendita,type } = data;
    console.log("ale-debug annodivendita", annoDiVendita)
    try {
        const statistichePazientiPromise = DashboardService.getStatistichePazienti(puntiVendita, annoDiVendita,type);
        const [statistichePazienti] = await Promise.all([statistichePazientiPromise]);
        return { "statistiche": statistichePazienti, "annoDiVendita": annoDiVendita,"type":type };

    } catch (err) {
        console.error(err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare le statistiche dei pazienti . Riprovare!
                <br />
                Se il problema persiste, contattare l' assistenza.
            </div>
        );
        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})


export const loadStatisticsProvenienze = createAsyncThunk('carica-statistiche-provenienze', async (data, { rejectWithValue }) => {
    console.log("[loadStatisticsVisits]");
    const { puntiVendita, annoDiVendita,type } = data;
    console.log("ale-debug annodivendita", annoDiVendita)
    try {
        const statisticheProvenienzePromise = DashboardService.getStatisticheProvenienze(puntiVendita, annoDiVendita,type);
        const [statistichePazienti] = await Promise.all([statisticheProvenienzePromise]);
        return { "statistiche": statistichePazienti, "annoDiVendita": annoDiVendita,"type":type };

    } catch (err) {
        console.error(err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare le statistiche delle provenienze . Riprovare!
                <br />
                Se il problema persiste, contattare l' assistenza.
            </div>
        );
        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})


export const dashboardsSatistiche = createSlice({
    name: 'dashboardsSatistiche', initialState, reducers: {}, extraReducers: (builder) => {
        builder
            .addCase(loadStatisticsVisits.pending, (state) => {

                state.loadingStatisticheVisite = true
                state.loadedStatisticheVisite = false
                state.errorStatisticheVisite = false
            })
            .addCase(loadStatisticsVisits.fulfilled, (state, action) => {
                state.loadingStatisticheVisite = false
                state.loadedStatisticheVisite = true
                state.statisticheVisite = action.payload
                state.errorStatisticheVisite = false

            })
            .addCase(loadStatisticsVisits.rejected, (state, action) => {
                state.loadingStatisticheVisite = false
                state.loadedStatisticheVisite = true
                state.statisticheVisite = {}
                state.errorStatisticheVisite = true
            })
            .addCase(loadStatisticsProvenienze.pending, (state) => {

                state.loadingStatisticheProvenienze= true
                state.loadedStatisticheProvenienze= false
                state.errorStatisticheProvenienze = false
            })
            .addCase(loadStatisticsProvenienze.fulfilled, (state, action) => {
                state.loadingStatisticheProvenienze = false
                state.loadedStatisticheProvenienze = true
                state.statisticheProvenienze = action.payload.statistiche
                state.annoDiVendita = action.payload.annoDiVendita;
                state.type = action.payload.type;
                state.errorStatisticheProvenienze = false

            })
            .addCase(loadStatisticsPatients.pending, (state) => {

                state.loadingStatistichePazienti = true
                state.loadedStatistichePazienti = false
                state.errorStatistichePazienti = false
            })
            .addCase(loadStatisticsPatients.fulfilled, (state, action) => {
                state.loadingStatistichePazienti = false
                state.loadedStatistichePazienti = true
                state.statistichePazienti = action.payload.statistiche
                state.annoDiVendita = action.payload.annoDiVendita
                state.type = action.payload.type;
                state.errorStatistichePazienti = false

            })
            .addCase(loadStatisticsPatients.rejected, (state, action) => {
                state.loadingStatistichePazienti = false
                state.loadedStatistichePazienti = true
                state.statistichePazienti = {}
                state.annoDiVendita = ""
                state.type = "categoria";
                state.errorStatistichePazienti = true
            })

    }
})


export default dashboardsSatistiche.reducer;