import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import ProvenienzaService from 'services/ProvenienzaService';
import {PROVENIENZE} from "../../configs/AppConfig";
import {errorMessage} from "../../utils/messages";
import {PROVENIENZE_ERROR, PROVENIENZE_LOADED, PROVENIENZE_VALUE} from "../../constants/AuthConstant";

export const initialState = PROVENIENZE;

export const loadProvenienze = createAsyncThunk('carica-provenizenze', async (data, {rejectWithValue}) => {
    console.log("[loadProvenienze]");

    try {
        const response = await ProvenienzaService.getProvenienze();
        const data = response.data
        const filteredDict = {};

        for (const item of data) {
            const id = item.documentId;
            const tipo = item.attributes.tipo;
            const nome = item.attributes.nome;
            const sottocollezione = item.attributes.sottocollezione;
            const categoria = item.attributes.categoria;
            if (!filteredDict[tipo]) {
                filteredDict[tipo] = {categoria,sottocollezione, nome: [{id, nome}]};
            } else {
                filteredDict[tipo].nome.push({id, nome});
            }
        }

        const resultFiltrato = Object.keys(filteredDict).map(tipo => ({tipo, ...filteredDict[tipo]}));
        localStorage.setItem(PROVENIENZE_VALUE, JSON.stringify(resultFiltrato))
        console.table(resultFiltrato)

        return resultFiltrato;
    } catch (err) {
        console.error(err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare le provenienze. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const provenienze = createSlice({
    name: 'provenienze', initialState, reducers: {}, extraReducers: (builder) => {
        builder
            .addCase(loadProvenienze.pending, (state) => {

                state.loadingProvenienze = true
                state.loadedProvenienze = false
                state.errorProvenienze = false
            })
            .addCase(loadProvenienze.fulfilled, (state, action) => {

                state.loadingProvenienze = false
                state.loadedProvenienze = true
                state.provenienze = action.payload
                localStorage.setItem(PROVENIENZE_LOADED, true)
                localStorage.setItem(PROVENIENZE_ERROR, false)
                state.errorProvenienze = false

            })
            .addCase(loadProvenienze.rejected, (state, action) => {

                state.loadingProvenienze = false
                state.loadedProvenienze = true
                state.provenienze = []
                localStorage.setItem(PROVENIENZE_LOADED, true)
                localStorage.setItem(PROVENIENZE_ERROR, true)
                localStorage.removeItem(PROVENIENZE_VALUE)
                state.errorProvenienze = true
            })
    }
})


export default provenienze.reducer;