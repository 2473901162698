import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import PazientiStatiTipiService from "../../services/PazientiStatiTipiService";
import {PAZIENTI_STATI_TIPI} from "../../configs/AppConfig";
import {errorMessage} from "../../utils/messages";
import {
    PAZIENTI_STATI_TIPI_ERROR,
    PAZIENTI_STATI_TIPI_LOADED,
    PAZIENTI_STATI_TIPI_VALUE
} from "../../constants/AuthConstant";

export const initialState = PAZIENTI_STATI_TIPI

export const loadPazientiStatiTipi = createAsyncThunk('carica-paziente-stati-tipi', async (data, {rejectWithValue}) => {
    console.log("[loadPazientiStatiTipi]");

    try {
        const response = await PazientiStatiTipiService.getStatiTipi();
        const data = response.data
        const filteredDict = {};

        for (const item of data) {
            const id = item.documentId;
            const stato = item.attributes.stato;
            const tipo = item.attributes.tipo;
            const sottocollezione = item.attributes.sottocollezione;

            if (!filteredDict[stato]) {
                filteredDict[stato] = { sottocollezione, tipo: [{ id, tipo }] };
            } else {
                filteredDict[stato].tipo.push({ id, tipo });
            }
        }

        const resultFiltrato = Object.keys(filteredDict).map(stato => ({ stato, ...filteredDict[stato] }));

        console.table(resultFiltrato)
        localStorage.setItem(PAZIENTI_STATI_TIPI_VALUE, JSON.stringify(resultFiltrato))

        return resultFiltrato;
    } catch (err) {
        console.error(err)
        let message = (<div>
            Qualcosa è andato storto nel recuperare le opzioni di stati/tipi dei pazienti. Riprovare!
            <br/>
            Se il problema persiste, contattare l' assistenza.
        </div>);
        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const pazientiStatiTipi = createSlice({
    name: 'pazientiStatiTipi', initialState, reducers: {}, extraReducers: (builder) => {
        builder
            .addCase(loadPazientiStatiTipi.pending, (state) => {

                state.loadingPazientiStatiTipi = true
                state.loadedPazientiStatiTipi = false
                state.pazientiStatiTipi = []
                state.errorPazientiStatiTipi = false
            })
            .addCase(loadPazientiStatiTipi.fulfilled, (state, action) => {

                state.loadingPazientiStatiTipi = false
                state.loadedPazientiStatiTipi = true
                state.pazientiStatiTipi = action.payload
                localStorage.setItem(PAZIENTI_STATI_TIPI_LOADED, true)
                localStorage.setItem(PAZIENTI_STATI_TIPI_ERROR, false)
                state.errorPazientiStatiTipi = false
            })
            .addCase(loadPazientiStatiTipi.rejected, (state, action) => {

                state.loadingPazientiStatiTipi = false
                state.loadedPazientiStatiTipi = true
                state.pazientiStatiTipi = []
                localStorage.setItem(PAZIENTI_STATI_TIPI_LOADED, true)
                localStorage.setItem(PAZIENTI_STATI_TIPI_ERROR, true)
                localStorage.removeItem(PAZIENTI_STATI_TIPI_VALUE)
                state.errorPazientiStatiTipi = true
            })
    }
})

export default pazientiStatiTipi.reducer;