import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {SCHEDA_PAZIENTE} from 'configs/AppConfig'
import SchedaPazienteService from "../../services/SchedaPazienteService";
import {errorMessage} from "../../utils/messages";

export const initialState = SCHEDA_PAZIENTE

export const loadSchedaPaziente = createAsyncThunk('carica-scheda-paziente', async (data, {rejectWithValue}) => {

    console.log("[loadSchedaPaziente]");

    try {
        const response = await SchedaPazienteService.getSchedaPaziente(data.id);
        let schedaPaziente = response.data.attributes
        const defaultTabKey = data.key
        const idPaziente = data.id;
        let schedaPazienteJson = {}
        console.log("ale-cronologia ",schedaPaziente)

        //vecchia gestione con campo json
        //schedaPaziente.cronologia = schedaPaziente.cronologia == null ? [] : schedaPaziente.cronologia;

        //nuova gestione con tabella cronologia a parte
        schedaPaziente.cronologia = schedaPaziente.sales_patients == null ? [] : schedaPaziente.sales_patients.data.map(item => {
            const { id, attributes } = item;
            const { data, note, stato, referente, tipologia } = attributes;

            return {
                id: id.toString(),
                data,
                note,
                stato,
                referente,
                tipologia
            };
        });

        schedaPazienteJson.schedaPaziente = schedaPaziente;
        schedaPazienteJson.schedaPaziente.id = response.data.documentId;
        schedaPazienteJson.defaultTabKey = defaultTabKey;

        console.table(schedaPazienteJson)
        return {schedaPaziente, defaultTabKey, idPaziente}
    } catch (err) {
        console.log("err", err)
        let message = (<div>
            Qualcosa è andato storto nel recuperare la scheda del paziente. Riprovare!
            <br/>
            Se il problema persiste, contattare l' assistenza.
        </div>);
        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const schedaPaziente = createSlice({
    name: 'schedaPaziente', initialState, reducers: {
        showLoading: (state) => {
            state.loading = true
        },
        updateSchedaPaziente: (state, action) => {
            const {updatedFields} = action.payload;
            console.log("updatedFields", updatedFields);

            // Se updatedFields è un oggetto, sovrascrivi i campi esistenti
            state.schedaPaziente = {
                ...state.schedaPaziente,
                ...updatedFields
            };

            /*
            // Verifica se updatedFields è un array
            if (Array.isArray(updatedFields)) {
                // Supponiamo che updatedFields contenga un array di oggetti
                // e che ogni oggetto abbia una chiave che specifica il campo da aggiornare
                updatedFields.forEach(updatedField => {
                    const { key, value } = updatedField;
                    // Aggiungi il valore alla lista corrispondente alla chiave
                    state.schedaPaziente[key] = [...(state.schedaPaziente[key] || []), value];
                });
            } else {



            }
               */

            console.log("schedaPaziente DOPO", state.schedaPaziente);
        },
        getSchedaPaziente: (state, action) => {
            return state.schedaPaziente
        },
    }, extraReducers: (builder) => {
        builder
            .addCase(loadSchedaPaziente.pending, (state) => {
                state.loadingSchedaPaziente = true
                state.loadedSchedaPaziente = false
                state.errorSchedaPaziente = false
            })
            .addCase(loadSchedaPaziente.fulfilled, (state, action) => {
                state.loadingSchedaPaziente = false
                state.loadedSchedaPaziente = true
                state.schedaPaziente = action.payload.schedaPaziente
                state.defaultTabKey = action.payload.defaultTabKey
                state.idPaziente = action.payload.idPaziente
                state.errorSchedaPaziente = false
            })
            .addCase(loadSchedaPaziente.rejected, (state, action) => {
                state.loadingSchedaPaziente = false
                state.loadedSchedaPaziente = true
                state.schedaPaziente = {}
                state.defaultTabKey = 1
                state.errorSchedaPaziente = true
            })

    }
})

export const {
    updateSchedaPaziente,
    showLoading,
    getSchedaPaziente
} = schedaPaziente.actions

export default schedaPaziente.reducer;