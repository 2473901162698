import fetch from 'auth/GenericFetchInterceptor'

const RoleService = {}


RoleService.getUsers = function () {
    return fetch({
        url: `/api/users?populate[binding_roles][populate][0]=store&populate=role`,
        method: 'GET'
    })
}

RoleService.getUsersWithFoto = function () {
    return fetch({
        url: `/api/users?populate[binding_roles][populate][0]=store&populate=foto_profilo`,
        method: 'GET'
    })
}

RoleService.getBindingRoles = function () {
    return fetch({
        url: `/api/binding-roles?populate=*`,
        method: 'GET'
    })
}

RoleService.getUsersRoles = function () {
    return fetch({
        url: `/api/users-permissions/roles`,
        method: 'GET'
    })
}

RoleService.createBindingRole = function (data) {
    return fetch({
        url: `/api/binding-roles`,
        method: 'POST',
        data: data
    })
}

RoleService.createUser = function (data) {
    return fetch({
        url: `/api/users`,
        method: 'POST',
        data: data
    })
}

RoleService.changePassword = function (data) {
    return fetch({
        url: `/api/auth/change-password`,
        method: 'POST',
        data: data
    })
}

RoleService.updateUser = function (data, id) {
    return fetch({
        url: `/api/users/${id}`,
        method: 'PUT',
        data: data
    })
}

RoleService.updateBindingRole = function (data, id) {
    return fetch({
        url: `/api/binding-roles/${id}`,
        method: 'PUT',
        data: data
    })
}

RoleService.deleteBindingRole = function (id) {
    return fetch({
        url: `/api/binding-roles/${id}`,
        method: 'DELETE'
    })
}

export default RoleService;